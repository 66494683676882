class FeedCategories {
  // remove filetype from categories
  fixupCategories() {
    $(".vrtx-feed .categories dt").each(function () {
      var category = $(this);
      var categoryText = $.trim(category.text());
      if (
        categoryText === "type"
      ) {
        category.next().remove();
      }
      category.remove();
    });
  }
}

export default new FeedCategories();
